import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridRow, GridColumn } from '@/components/Grid'
import { ProjectThumb } from '@/components/ProjectThumb'
import { Block } from '@/components/Block'
import * as styles from './ProjectThumbs.module.scss'

const ProjectThumbs = ({ projects, prev, next, background, scrollEnabled }) => {
  return (
    <Block
      prev={prev}
      next={next}
      background={background}
      scrollEnabled={scrollEnabled}
    >
      <Grid>
        <GridRow>
          {projects.map((project) => {
            return (
              <GridColumn className={styles.column} width="4" key={project.id}>
                <ProjectThumb
                  title={project.title}
                  url={project.route}
                  image={project.image}
                  background="light-brown"
                />
              </GridColumn>
            )
          })}
        </GridRow>
      </Grid>
    </Block>
  )
}

ProjectThumbs.defaultProps = {
  projects: [],
  next: '',
  prev: '',
  background: '',
  scrollEnabled: true,
}

ProjectThumbs.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.PropTypes.object),
  next: PropTypes.string,
  prev: PropTypes.string,
  background: PropTypes.string,
  scrollEnabled: PropTypes.bool,
}

export { ProjectThumbs }

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getSlug } from '@/utils/getSlug'
import { sortBy } from '@/utils/sortBy'
import { linkResolver } from '@/prismic/helpers/linkResolver'
import { getImage } from '@/prismic/helpers/getImage'
import { getCategories } from '@/prismic/helpers/getCategories'
import Projects from '@/templates/Projects'

const ProjectsPrismic = ({ data, pageContext }) => {
  const {
    allPrismicProject,
    allPrismicCategory,
    allPrismicProfile,
    allPrismicTag,
  } = data
  const categoryUID = pageContext?.category?.uid
  const rootPath = linkResolver({ uid: 'projects' })
  const currentPath = categoryUID
    ? linkResolver({ type: 'category', uid: categoryUID })
    : rootPath

  let projects = []
  const teamFilterList = []
  const featureFilterList = [
    { title: 'All Features', id: 'all', activeTitle: 'Features' },
  ]
  const locationFilterList = [
    { title: 'All locations', id: 'all', activeTitle: 'Location' },
  ]

  allPrismicProject.edges.forEach(({ node }) => {
    const projectTeamMembers = []
    if (node.data.team) {
      node.data.team.forEach((teamMember) => {
        const profileUID = teamMember?.profile?.uid
        if (profileUID) {
          const matchingProfile = allPrismicProfile.edges.find((obj) => {
            return obj.node.uid === profileUID
          })
          if (matchingProfile) {
            const matchingName = matchingProfile.node?.data?.name
            // add to filter list if it doesn't already exist
            if (teamFilterList.indexOf(matchingName) === -1) {
              teamFilterList.push(matchingName)
            }
            // add team members name to project team array
            projectTeamMembers.push(matchingName)
          }
        }
      })
    }

    const projectTags = []
    if (node.data.tags) {
      node.data.tags.forEach((item) => {
        const tagUID = item.tag?.uid
        if (tagUID) {
          const matchingTag = allPrismicTag.edges.find((obj) => {
            return obj.node.uid === tagUID
          })
          if (matchingTag) {
            const matchingTagTitle = matchingTag.node?.data?.title
            const matchingTagId = getSlug(matchingTagTitle)
            // add to filter list if it doesn't already exist
            if (!featureFilterList.find((item) => item.id === matchingTagId)) {
              // if (featureFilterList.indexOf(matchingTagTitle) === -1) {
              featureFilterList.push({
                id: matchingTagId,
                title: matchingTagTitle,
              })
            }
            // add to project tags array
            projectTags.push(matchingTagId)
          }
        }
      })
    }

    const projectLocations = []
    if (node.data.country) {
      const locationId = getSlug(node.data.country)
      if (!locationFilterList.find((item) => item.id === locationId)) {
        locationFilterList.push({
          id: locationId,
          title: node.data.country,
        })
      }
      projectLocations.push(locationId)
    }

    const currentYear = new Date().getFullYear()

    const projectTitle = node.data?.title
    projects.push({
      title: projectTitle,
      id: node.id,
      slug: getSlug(projectTitle),
      route: linkResolver(node),
      image: getImage(node.data?.image),
      year: node.data?.year_end || currentYear,
      team: projectTeamMembers,
      categories: node.data.categories.map((item) => {
        return item.category?.uid ? item.category.uid : null
      }),
      tags: projectTags,
      location: projectLocations,
    })
  })

  if (categoryUID) {
    projects = projects.filter((project) =>
      project.categories.includes(categoryUID)
    )
  }

  // get categories list
  let categories = getCategories({
    rootPath,
    data: allPrismicCategory,
  })
  if (!!categories?.length) {
    categories = sortBy(categories, 'slug')
  }

  return (
    <Projects
      projects={projects}
      rootPath={rootPath}
      currentPath={currentPath}
      categories={categories}
      filters={[
        // {
        //   title: 'Team',
        //   id: 'team',
        //   values: teamFilterList.sort(),
        // },
        {
          title: 'Location',
          id: 'location',
          values: locationFilterList.sort(),
        },
        {
          title: 'Features',
          id: 'tags',
          values: featureFilterList.sort(),
        },
      ]}
      sortOptions={[
        {
          title: 'New–Old',
          activeTitle: 'Sort',
          id: 'sort-newest-oldest',
          value: 'year',
          reverse: true,
        },
        {
          title: 'Old–New',
          id: 'sort-oldest-newest',
          value: 'year',
        },
        {
          title: 'A–Z',
          id: 'sort-a-z',
          value: 'slug',
        },
      ]}
    />
  )
}

ProjectsPrismic.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default ProjectsPrismic

export const pageQuery = graphql`
  query Projects {
    allPrismicCategory {
      edges {
        node {
          id
          uid
          type
          data {
            title
          }
        }
      }
    }
    allPrismicProject {
      edges {
        node {
          id
          uid
          type
          data {
            title
            country
            year_start
            year_end
            team {
              profile {
                uid
              }
            }
            image {
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
              url
              alt
            }
            categories {
              category {
                uid
              }
            }
            tags {
              tag {
                uid
              }
            }
          }
        }
      }
    }
    allPrismicProfile {
      edges {
        node {
          uid
          data {
            name
          }
        }
      }
    }
    allPrismicTag {
      edges {
        node {
          uid
          data {
            title
          }
        }
      }
    }
  }
`

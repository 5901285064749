import { getSlug } from '@/utils/getSlug'

export const getCategories = ({ rootPath, data }) => {
  const categories = []
  if (data.edges.length) {
    categories.push({
      title: 'All Project Types',
      activeTitle: 'Project Type',
      slug: '_all',
      path: rootPath,
      id: 'all',
    })
    data.edges.forEach((category) => {
      const categoryTitle = category.node?.data?.title
      categories.push({
        title: categoryTitle,
        slug: getSlug(categoryTitle),
        path: `${rootPath}/${category.node.uid}`,
        id: category.node.id,
      })
    })
  }

  return categories
}

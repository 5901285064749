import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { navigate } from 'gatsby'
import { sortBy } from '@/utils/sortBy'
import { SelectBox } from '@/components/SelectBox'
import { ProjectThumbs } from '@/blocks/ProjectThumbs'
import { FilterBar } from '@/components/FilterBar'
import { Container } from '@/components/Container'
import * as styles from './Projects.module.scss'

const Projects = ({
  projects,
  currentPath,
  categories,
  filters,
  sortOptions,
}) => {
  const isMobile = useMedia({ maxWidth: 768 })
  const [activeFilters, setActiveFilters] = useState({})
  const [showFilters, setShowFilters] = useState(false)

  const currentCategory = categories.find((item) => item.path === currentPath)
  const handleSelectCategory = (category) => {
    if (category?.path !== currentPath) navigate(category.path)
  }

  const defaultSortOption = !!sortOptions?.length && sortOptions[0]
  const [activeSorting, setActiveSorting] = useState(defaultSortOption)

  const handleSelectSortOption = (sortOption) => {
    setActiveSorting(sortOption)
  }

  const handleToggleFilters = () => setShowFilters((prev) => !prev)

  const sortProjects = (projects, key, reverse) => {
    const sortedProjects = sortBy(projects, key)
    if (reverse) return sortedProjects.reverse()
    return sortedProjects
  }

  const filterProjects = (projects, filters) => {
    return projects.filter((project) => {
      return Object.entries(filters).every(([key, values]) =>
        values.every((value) => project[key].includes(value))
      )
    })
  }

  const filteredAndSortedProjects = useMemo(() => {
    const filteredProjects = filterProjects(projects, activeFilters)
    const sortedProjects = activeSorting
      ? sortProjects(
          filteredProjects,
          activeSorting?.value,
          activeSorting?.reverse
        )
      : filteredProjects
    return sortedProjects
  }, [projects, activeFilters, activeSorting])

  return (
    <div className={styles.Projects}>
      <div className={styles.header}>
        <Container>
          {isMobile && (
            <button
              className={styles.btnToggleFilters}
              onClick={handleToggleFilters}
            >
              <span className={styles.btnToggleFilters__text}>
                {showFilters ? 'Filters' : 'Filters'}
              </span>
            </button>
          )}
          {(!isMobile || showFilters) && (
            <div className={styles.filters}>
              {!!categories.length && (
                <SelectBox
                  currentId={currentCategory?.id}
                  items={categories}
                  onSelect={handleSelectCategory}
                  align={isMobile ? 'bottomLeft' : 'bottomRight'}
                />
              )}
              {!!filters.length &&
                filters.some((f) => f.values?.length > 1) && (
                  <FilterBar
                    filters={filters}
                    activeFilters={activeFilters}
                    setActiveFilters={setActiveFilters}
                  />
                )}
              {!!sortOptions.length && (
                <SelectBox
                  currentId={activeSorting?.id}
                  items={sortOptions}
                  onSelect={handleSelectSortOption}
                  align={isMobile ? 'bottomLeft' : 'bottomRight'}
                />
              )}
            </div>
          )}
        </Container>
      </div>
      <div className={styles.thumbs}>
        {filteredAndSortedProjects.length === 0 ? (
          <Container indent={1}>
            <div className={styles.noResults}>
              <div className={styles.noResults__text}>No results found</div>
            </div>
          </Container>
        ) : (
          <ProjectThumbs
            projects={filteredAndSortedProjects}
            background="transparent"
            scrollEnabled={false}
          />
        )}
      </div>
    </div>
  )
}

Projects.propTypes = {
  currentPath: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.object),
  sortOptions: PropTypes.arrayOf(PropTypes.object),
}

export default Projects

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import DropDownSVG from '@/images/dropdown.inline.svg'
import * as styles from './Dropdown.module.scss'

const Dropdown = ({ children, label, align, isOpen, setIsOpen }) => {
  const handleClick = () => setIsOpen((prev) => !prev)
  const handleClickOutside = () => setIsOpen(false)

  return (
    <div className={styles.Dropdown} data-open={isOpen}>
      {isOpen && (
        <div
          role="button"
          className={styles.overlay}
          onClick={handleClickOutside}
          aria-label="Close"
          onKeyDown={() => {}}
          tabIndex="0"
        />
      )}
      <div>
        <button type="button" className={styles.heading} onClick={handleClick}>
          <h4>{label}</h4>
          <div className={styles.heading__icon}>
            <DropDownSVG />
          </div>
        </button>
        {isOpen && (
          <div
            className={classNames(styles.children, {
              [styles[align]]: !!align,
            })}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  align: PropTypes.oneOf(['bottomLeft', 'bottomRight']),
  isOpen: PropTypes.bool,
}

export { Dropdown }

import React from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { SelectBox } from '@/components/SelectBox'
import * as styles from './FilterBar.module.scss'

const FilterBar = ({ filters, activeFilters, setActiveFilters }) => {
  const isMobile = useMedia({ maxWidth: 768 })
  const handleSelect = (id, value) => {
    if (value.id === 'all') {
      setActiveFilters({ ...activeFilters, [id]: [] })
    } else {
      setActiveFilters({ ...activeFilters, [id]: [value.id] })
    }
  }
  const filterList = filters?.filter((f) => f.values.length > 0) || []

  return (
    <div className={styles.FilterBar}>
      {filterList.map((filter) => {
        const activeIds = activeFilters[filter.id]
        return (
          <SelectBox
            key={filter.id}
            id={filter.id}
            currentId={!!activeIds?.length ? activeIds[0] : 'all'}
            items={filter.values}
            onSelect={(value) => handleSelect(filter.id, value)}
            placeholder={filter.title}
            align={isMobile ? 'bottomLeft' : 'bottomRight'}
          />
        )
      })}
    </div>
  )
}

FilterBar.defaultProps = {
  activeFilters: {},
}

FilterBar.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object),
  activeFilters: PropTypes.object,
  setActiveFilters: PropTypes.func.isRequired,
}

export { FilterBar }

import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Dropdown } from '@/components/Dropdown'
import * as styles from './SelectBox.module.scss'

const SelectBox = ({ items, currentId, onSelect, align, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentItem = items.find((item) => item.id === currentId)
  const currentItemTitle = currentItem?.activeTitle || currentItem?.title

  const handleClickItem = (item) => {
    if (typeof onSelect === 'function') onSelect(item)
    setIsOpen(false)
  }

  if (!items?.length) return null

  return (
    <Dropdown
      label={currentItemTitle || placeholder}
      align={align}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <ul className={styles.SelectBox}>
        {items.map((item) => (
          <li
            key={item.id}
            className={classNames(styles.item, {
              [styles.isActive]: item.id === currentId,
            })}
          >
            <button
              className={styles.item__inner}
              onClick={() => handleClickItem(item)}
            >
              {item.title}
            </button>
          </li>
        ))}
      </ul>
    </Dropdown>
  )
}

SelectBox.propTypes = {
  currentId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  align: PropTypes.string,
  placeholder: PropTypes.string,
}

export { SelectBox }
